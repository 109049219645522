<template>
  <div>
    <iframe id="powerBIFrame"
            title="sykehusinnkjop data"
            :src="url"
            frameborder="0"
            allowFullScreen="true"></iframe>
    <!-- <div id="powerbiContainer"
         class="powerbi-container"></div> -->
  </div>
</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';

  export default {
    // asyncData({store}) {
    //   let promisesArr = '';

    //   promisesArr = [...promisesArr, store.dispatch('PowerBIStore/fetchPowerBIAccessInfo')];

    //   return promisesArr ? Promise.all(promisesArr) : '';
    // },
    computed: {
      ...mapGetters('PowerBIStore', ['getAccessInfo']),
      ...mapState('PowerBIStore', {
        embedUrl: (state) => state.accessInfo.embedUrl,
        reportId: (state) => state.accessInfo.id,
        accessToken: (state) => state.accessInfo.accessToken,
      }),
      url() {
        return this.$route.query.view === 'interpretation'
          ? 'https://app.powerbi.com/reportEmbed?reportId=0120152a-6b51-4942-b1c7-ea61942f86b3&autoAuth=true&ctid=047627d3-456b-4a58-9dbf-5a447828124c&navContentPaneEnabled=true&filterPaneEnabled=false'
          : 'https://app.powerbi.com/reportEmbed?reportId=95ae77b5-e81d-4ca9-86dd-f0fef56dc10b&autoAuth=true&ctid=047627d3-456b-4a58-9dbf-5a447828124c&navContentPaneEnabled=false&filterPaneEnabled=false';
      }
    },
    watch: {
      // embedUrl() {
      //   if (typeof window !== 'undefined') {
      //     this.embedPowerBi();
      //   }
      // }
    },
    methods: {
      ...mapMutations('PowerBIStore', ['clearStore']),
      // embedPowerBi() {
      //   import('powerbi-client').then(({service, factories, models}) => {
      //     const embedContainer = document.getElementById('powerbiContainer');
      //     const accessInfo = this.$store.state.PowerBIStore.accessInfo;
      //     const powerbiService = new service.Service(
      //       factories.hpmFactory,
      //       factories.wpmpFactory,
      //       factories.routerFactory
      //     );

      //     const embedConfig = {
      //       type: 'report',
      //       id: accessInfo.id,
      //       accessToken: accessInfo.accessToken,
      //       embedUrl: accessInfo.embedUrl,
      //       tokenType: models.TokenType.Embed,
      //       permissions: models.Permissions.Read,
      //       pageName: 'ReportSection',
      //       settings: {
      //         filterPaneEnabled: false,
      //         navContentPaneEnabled: false,
      //       },
      //     };

      //     const report = powerbiService.embed(embedContainer, embedConfig);

      //     report.on('loaded', () => {
      //       console.log('Report Loaded');
      //     });
      //   });
      // }
    },
    // beforeDestroy() {
    //   this.clearStore();
    // },
    mounted() {
      if (typeof window !== 'undefined') {
        const iframe = document.getElementById('powerBIFrame');
        iframe.style.width = (window.innerWidth - 50) + 'px';
        iframe.style.height = window.innerHeight + 'px';
      }
    }
  };
</script>

<style>
  .powerbi-wrapper {
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  /* .powerbi-container {
    width: 100%;
    height: 750px;
  } */
</style>
